<template>
  <div class="image-placeholder">
    <span class="container">
      <span class="label">LOADING...</span>
    </span>
  </div>
</template>
<script>
export default {
  name: 'image-placeholder'
};
</script>
<style lang="scss" scoped>
  .image-placeholder {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container {
      text-align: center;
    }

    .label {
      font-weight: bold;
      font-size: 10px;
    }
  }
</style>
